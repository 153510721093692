import React, { useEffect, useState } from "react";
import * as styles from "./CardSearch.module.scss";
import * as stylesCard from "../Card.module.scss";
import Image from "next/image";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import useTranslation from "next-translate/useTranslation";
import {
  DEFAULT_LOCALE,
  SEASON_WINTER,
  getLangValue,
  getLangValueWithoutFallback,
} from "@/utils/global";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import Link from "next/link";
import { useRouter } from "next/router";
import CustomLink from "../../CustomLink";
import { enableBodyScroll } from "@/utils/scroll";

/**
 * Retourne le nom du mois (1 = Janvier, 2 = Février, etc.).
 */
function getMonthName(monthNumber) {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  return months[monthNumber - 1] || "";
}

/**
 * Retourne l'index du mois (0 = janvier, 1 = février, etc.) à partir d'un nom de mois.
 */
function getMonthIndexFromString(str) {
  const map = {
    janvier: 0,
    février: 1,
    fevrier: 1, // parfois "février" peut être mal orthographié
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    aout: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    decembre: 11,
    décembre: 11,
  };
  return map[str] ?? -1;
}

/**
 * Renvoie un tableau de Date en filtrant uniquement celles qui sont strictement >= "maintenant".
 * Puis les trie par ordre chronologique (la plus proche d'abord).
 */
function getFutureDates(dates) {
  const now = new Date();
  return dates
    .filter((d) => d >= now)
    .sort((a, b) => a - b);
}

/**
 * Récupère toutes les dates depuis data.ouverture.periodesOuvertures sous forme de Date JS.
 */
function getDatesFromPeriodesOuvertures(ouverture) {
  if (!ouverture || !ouverture.periodesOuvertures) return [];
  return ouverture.periodesOuvertures
    .map((p) => {
      // p.dateDebut est au format "YYYY-MM-DD"
      // on parse pour construire un objet Date
      const [year, month, day] = p.dateDebut.split("-");
      return new Date(+year, +month - 1, +day); // JS commence à 0 pour les mois
    })
    .filter((d) => !isNaN(d.getTime())); // retire les dates invalides
}

/**
 * Récupère toutes les dates présentes dans periodeEnClair.libelleFr (en cas de fallback).
 * On recherche tous les patterns "JJ mois [AAAA]" et on crée des Date JS.
 */
function getDatesFromPeriodeEnClair(ouverture) {
  if (!ouverture || !ouverture.periodeEnClair || !ouverture.periodeEnClair.libelleFr) {
    return [];
  }
  const text = ouverture.periodeEnClair.libelleFr;
  // Recherche toutes les occurrences de "JJ mois AAAA" ou "JJ mois"
  const regex = /(\d{1,2})\s+(janvier|février|mars|avril|mai|juin|juillet|août|aout|septembre|octobre|novembre|décembre)(?:\s+(\d{4}))?/gi;
  const foundDates = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    const day = parseInt(match[1], 10);
    const monthIndex = getMonthIndexFromString(match[2].toLowerCase());
    let year = match[3] ? parseInt(match[3], 10) : new Date().getFullYear();
    if (!isNaN(day) && monthIndex >= 0 && !isNaN(year)) {
      foundDates.push(new Date(year, monthIndex, day));
    }
  }
  return foundDates;
}

export default function CardSearch(props) {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const { data, className, variant } = props;
  const content = data?.content || null;
  const dataFromApidae = !!data?.identifier;

  const [imageBySeason, setImageBySeason] = useState(null);

  // Définition de la date actuelle
  const currentDate = new Date();

  useEffect(() => {
    if (content) {
      let seasonMode = SEASON_WINTER;
      if (typeof window !== "undefined") {
        seasonMode = localStorage.getItem("seasonMode");
      }

      let heroImages = [];
      if (content.hero?.length > 0) {
        content.hero.forEach((item) => {
          if (item.Image) {
            heroImages.push(item.Image);
          }
        });
      }

      const images =
        content.card_thumbnail?.length > 0 ? content.card_thumbnail : [];

      if (images?.length === 0) {
        const image =
          seasonMode === SEASON_WINTER
            ? heroImages[0]
            : heroImages.length > 1
            ? heroImages[1]
            : heroImages[0];
        setImageBySeason(image);
      } else {
        const image =
          seasonMode === SEASON_WINTER
            ? images[0]
            : images.length > 1
            ? images[1]
            : images[0];
        setImageBySeason(image);
      }
    }
  }, [content]);

  let cardThumbnail = null;
  let cardName = null;
  let cardDescription = null;
  let cardLink = "";

  // --- Cas APIDAE
  if (dataFromApidae) {
    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

    // 1) Illustration
    if (data.illustrations?.length) {
      let langTradIllustration = data.illustrations[0].traductionFichiers.find(
        (item) => item.locale === locale
      );
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => item.locale === "en"
        );
      }
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => item.locale === DEFAULT_LOCALE
        );
      }
      if (langTradIllustration) {
        cardThumbnail = {
          filename: langTradIllustration.url,
          alt: data.illustrations[0]?.legende
            ? getLangValueWithoutFallback(
                data.illustrations[0].legende,
                langKeyTrad
              )
            : "",
        };
      }
    }

    // 2) Nom
    cardName = getLangValue(data.nom, langKeyTrad);

    // 3) Description
    cardDescription =
      data.presentation && data.presentation.descriptifCourt
        ? getLangValueWithoutFallback(
            data.presentation.descriptifCourt,
            langKeyTrad
          )
        : null;

    // 4) Lien
    cardLink =
      "/" +
      (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
      "a/" +
      slugify(cardName) +
      "-" +
      data.id;
  }
  // --- Cas CMS (Storyblok, etc.)
  else if (content) {
    cardThumbnail = imageBySeason;
    cardName =
      content?.hero?.length > 0 ? content?.hero[0]?.title : content.name;
    cardDescription = content.short_description;

    // Cas spécial pour livecams
    if (data.full_slug && data.full_slug.includes("livecams/")) {
      if (content.illustration && content.illustration.length > 0) {
        cardThumbnail = content.illustration[0];
      }
      let url = data.full_slug.split("/livecams/");
      if (url.length === 1) {
        cardLink = "/livecams/webcam?webcam=" + url[0];
      } else if (url.length > 1) {
        cardLink = url[0] + "/livecams/webcam?webcam=" + url[1];
      }
      cardName = `${data.name} - ${t("common:lumiplan.livecams.webcam")} `;
    } else {
      cardLink = data.full_slug ? `/${data.full_slug}` : "";
    }
  }

  /**
   * Pour les objets de type FETE_ET_MANIFESTATION, on cherche la première date future.
   */
  let eventDate = null;
  if (data && data.type === "FETE_ET_MANIFESTATION" && data.ouverture) {
    const periodesDates = getDatesFromPeriodesOuvertures(data.ouverture);
    let futureDates = getFutureDates(periodesDates);
    if (futureDates.length === 0) {
      const textDates = getDatesFromPeriodeEnClair(data.ouverture);
      futureDates = getFutureDates(textDates);
    }
    if (futureDates.length > 0) {
      eventDate = futureDates[0];
    }
  }

  /**
   * Formatage "jour / mois" (ex: "6 Mars")
   */
  function formatEventDate(dateObj) {
    if (!dateObj) return null;
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const monthName = getMonthName(month);
    return { day, monthName };
  }

  const eventDateParts = eventDate ? formatEventDate(eventDate) : null;

  return (
    <CustomLink
      onClick={() => {
        enableBodyScroll(document);
      }}
      link={cardLink}
      className={`${className} ${stylesCard.card} ${styles.cardSearch} ${styles[variant]} relative flex lg:flex-col`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden self-start rounded-xl mr-3 lg:mr-0 lg:mb-5 bg-gray-600`}
      >
        {cardThumbnail && cardThumbnail.filename && (
          <Image
            src={cardThumbnail.filename}
            alt={cardThumbnail.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 25vw"
            className="object-cover"
          />
        )}

        {/* Affichage de la date pour FETE_ET_MANIFESTATION s'il y a une date future */}
        {eventDateParts && (
          <div className={styles.eventDate}>
            <span className={styles.eventDateDay}>{eventDateParts.day}</span>
            <span className={styles.eventDateMonth}>{eventDateParts.monthName}</span>
          </div>
        )}
      </div>

      <div className={`${styles.cardContent} flex-1 text-white`}>
        <Text as="h6" tag="p" className="mb-1 pr-4 lg:mb-2 lg:pr-0 lg:w-3/4">
          {cardName}{" "}
          {data && data.type === "ACTIVITE" &&
            data.ouverture &&
            data.ouverture.periodesOuvertures &&
            data.ouverture.periodesOuvertures.length > 0 &&
            (currentDate >= new Date(data.ouverture.periodesOuvertures[0].dateDebut) &&
            currentDate <= new Date(data.ouverture.periodesOuvertures[0].dateFin)
              ? "Ouvert"
              : "Fermé")}
          {console.log("data : ", data)}
        </Text>
        <Text as="body2" className="line-clamp-2 pr-4 lg:pr-0 lg:w-11/12">
          {cardDescription}
        </Text>
        {cardLink && (
          <ArrowLink className="mt-4 text-buttonColor-primary">
            {t("actions.explore")}
          </ArrowLink>
        )}
      </div>
    </CustomLink>
  );
}
